const LOCAL_STORAGE_NAMESPACE = 'pennyclerk_v3'

export const getLocalStorageValue = (key) =>
  JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_NAMESPACE}_${key}`))

export const setLocalStorageValue = (key, value) =>
  localStorage.setItem(`${LOCAL_STORAGE_NAMESPACE}_${key}`, JSON.stringify(value))

export const isProduction = () => window.location.origin === 'https://pennyclerk.com'

export const getApiBaseUrl = () => isProduction() ? 'https://api.pennyclerk.com' : 'http://127.0.0.1:1337'
