import PropTypes from 'prop-types'
import styled from 'styled-components'

import '../styles/phone.css'
import { APP_NAME } from '../constants'

const Phone = ({ color = 'silver', image, scale = 1.0, onClick, style }) =>
  <Container
    className={`marvel-device iphone8 ${color}`}
    scale={scale}
    onClick={onClick}
    style={style}
  >
    <div className="top-bar"></div>
    <div className="sleep"></div>
    <div className="volume"></div>
    <div className="camera"></div>
    <div className="sensor"></div>
    <div className="speaker"></div>
    <div className="screen">
      {
        Boolean(image) &&
        <img src={image} alt={APP_NAME} style={{ width: '100%' }} />
      }
    </div>
    <div className="home"></div>
    <div className="bottom-bar"></div>
  </Container>

Phone.propTypes = {
  color: PropTypes.oneOf([
    'silver',
    'black',
    'gold',
  ]),
  scale: PropTypes.number,
  image: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

const Container = styled.div`
  cursor: ${props => props.onClick ? 'pointer' : 'default'};
  transform: scale(${props => props.scale});
  transform-origin: top;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export default Phone
