import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { redirect } from 'react-router-dom'

import BlueScreen from '../common/components/BlueScreen'
import { APP_NAME } from '../common/constants'

const NotFound = () => {
  const [redirectToHome, setRedirectToHome] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (redirectToHome) {
    return redirect('/')
  }

  return createPortal(
    <BlueScreen
      heading={APP_NAME}
      onClick={() => setRedirectToHome(true)}
    >
      <p>
        A fatal exception 404 has occurred at {window.location.pathname}.
        The current application will be terminated.
      </p>
      <p>Click here to continue _</p>
    </BlueScreen>,
    document.getElementById('blue-screen'),
  )
}

export default NotFound
