import { createRoot } from 'react-dom/client'

import Routes from './bootstrap/Routes'
import registerServiceWorker from './bootstrap/registerServiceWorker'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<Routes tab="home" />)

registerServiceWorker()
