import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Content, Row } from './Grid'
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from '../styles/responsive'
import LOGO_MOBILE from '../../assets/images/pennyclerk-mobile.png'
import LOGO_DESKTOP from '../../assets/images/pennyclerk-desktop.png'
import { APP_NAME } from '../constants'

const AppBar = () =>
  <Container>
    <Row>
      <Content>
        <Inner>
          <Link to="/">
            <H1>
              <DesktopLogo src={LOGO_DESKTOP} alt={APP_NAME} />
              <MobileLogo src={LOGO_MOBILE} alt={APP_NAME} />
            </H1>
          </Link>
        </Inner>
      </Content>
    </Row>
  </Container>

export const APP_BAR_HEIGHT = 120

const LOGO_HEIGHT_DESKTOP = 40
const LOGO_HEIGHT_MOBILE = 50

const Container = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: ${APP_BAR_HEIGHT}px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    align-items: center;
  }
`

const Inner = styled.div`
  align-items: center; justify-content: space-between;
  display: flex;
  flex-direction: row;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-grow: 1;
  }
`

const H1 = styled.h1`
  margin: 0;
  height: ${LOGO_HEIGHT_DESKTOP}px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    height: ${LOGO_HEIGHT_MOBILE}px;
  }
`

const DesktopLogo = styled.img`
  height: ${LOGO_HEIGHT_DESKTOP}px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    display: none;
  }
`

const MobileLogo = styled.img`
  height: ${LOGO_HEIGHT_MOBILE}px;

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    display: none;
  }
`

export default AppBar
