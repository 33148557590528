export const FontFamily = {
  PRIMARY: '"Karla", sans-serif',
  HEADING: 'Arapey',
  MONOSPACE: `"VT323", monospace`,
}

export const FontSize = {
  Desktop: {
    SMALL: '13px',
    MEDIUM: '16px',
    LARGE: '22px',
    H1: '48px',
    H2: '34px',
  },
  Mobile: {
    SMALL: '13px',
    MEDIUM: '16px',
    LARGE: '22px',
    H1: '34px',
    H2: '26px',
  },
}
