// Purples

export const GRAPES = '#403A57'
export const PLUM = '#494269'
export const PLUM_LIGHT = '#36314D'

export const VIOLET = '#504B6D'
export const VIOLET_MEDIUM = '#8780A3'
export const VIOLET_LIGHT = '#C9C4E0'

// Yellows

export const GOLD = '#FFD465'

// Grays

export const IVORY = '#FFF'
export const SILVER = '#D5D5D5'
export const GRAY = '#686868'
export const ONYX = '#2F2F2F'
export const ONYX_DARK = '#292929'
export const PLATINUM = '#7A8792'
export const PLATINUM_DARK = '#485158'

// Reds

export const RUBY = '#FF3B69'
export const ORANGE_LIGHT = '#FFE7DB'

// Greens

export const EMERALD = '#A0FF66'

// Theme

export const PRIMARY_COLOR = PLUM
export const BACKGROUND_COLOR = IVORY
export const HEADING_COLOR = PLATINUM_DARK
export const TEXT_COLOR = PLATINUM
export const LINK_COLOR = ONYX_DARK
