import styled from 'styled-components'

import { GOLD, ONYX_DARK } from '../styles/colors'
import { FontSize } from '../styles/fonts'
import { MOBILE_MAX_WIDTH } from '../styles/responsive'

const Button = styled.button`
  background-color: ${GOLD};
  border: none;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  color: ${ONYX_DARK};
  font-size: ${FontSize.Desktop.SMALL};
  font-weight: 600;
  min-width: 200px;
  outline: none;
  opacity: 1;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 200ms;
  cursor: pointer;
  width: ${props => props.stretch ? '100%' : 'auto'};

  &:active {
    opacity: 0.6;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: ${FontSize.Mobile.SMALL};
    padding: 13px 20px;
    width: 100%;
  }
`

export default Button
