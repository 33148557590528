import styled from 'styled-components'
import PropTypes from 'prop-types'

const Carousel = ({ currentIndex, images, onChange, style }) =>
  <Container style={style}>
    <HiddenPreloadedImages>
      {
        images.map(image => <img key={image} src={image} alt="" />)
      }
    </HiddenPreloadedImages>
    {
      images.map((image, index) =>
        <Circle
          key={index}
          $isActive={index === currentIndex}
          onClick={() => onChange(index)}
        />,
      )
    }

  </Container>

Carousel.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
}

const CIRCLE_SIZE = 12

const Circle = styled.div`
  background-color: ${props => props.$isActive ? '#54507C' : '#E6E3F7'};
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  user-select: none;
  width: ${CIRCLE_SIZE}px; height: ${CIRCLE_SIZE}px;
  -webkit-tap-highlight-color: transparent;

  &:last-child {
    margin-right: 0;
  }
`

const Container = styled.div`
  display: flex;
`

const HiddenPreloadedImages = styled.div`
  display: none;
`

export default Carousel
