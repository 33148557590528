import styled from 'styled-components'

import { FontFamily, FontSize } from '../styles/fonts'
import { HEADING_COLOR } from '../styles/colors'
import { MOBILE_MAX_WIDTH } from '../styles/responsive'

const LINE_HEIGHT = 1.7

export const Heading = styled.h1`
  color: ${HEADING_COLOR};
  font-family: ${FontFamily.HEADING};
  font-weight: 400;
  font-style: normal;
  font-size: ${FontSize.Desktop.H1};
  line-height: 1.3;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: ${FontSize.Mobile.H1};
  }
`

export const Subheading = styled.h2`
  color: ${HEADING_COLOR};
  font-family: ${FontFamily.HEADING};
  font-weight: 400;
  font-style: normal;
  font-size: ${FontSize.Desktop.H2};
  line-height: 1.3;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: ${FontSize.Mobile.H2};
  }
`

export const Ul = styled.ul`
`

export const Li = styled.li`
  margin-bottom: 1em;
  line-height: ${LINE_HEIGHT};
`

export const P = styled.p`
  line-height: ${LINE_HEIGHT};
`
