import { createGlobalStyle } from 'styled-components'

import { BACKGROUND_COLOR, TEXT_COLOR } from './colors'
import { FontFamily, FontSize } from './fonts'
import { MOBILE_MAX_WIDTH } from './responsive'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-smoothing: antialiased;
    height: 100%;
  }

  body {
    background-color: ${BACKGROUND_COLOR};
    font-family: ${FontFamily.PRIMARY};
    font-size: ${FontSize.Desktop.MEDIUM};
    font-weight: 400;
    font-style: normal;
    color: ${TEXT_COLOR};
    margin: 0;

    @media (max-width: ${MOBILE_MAX_WIDTH}) {
      font-size: ${FontSize.Mobile.MEDIUM};
    }
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default GlobalStyle
