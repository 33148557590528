import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Content, Row } from '../common/components/Grid'
import { Heading, P } from '../common/components/Typography'
import DualHero from '../common/components/DualHero'
import Phone from '../common/components/Phone'
import { AppStoreBadge, GooglePlayBadge } from '../common/components/AppBadges'
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from '../common/styles/responsive'
import { APP_BAR_HEIGHT } from '../common/components/AppBar'
import Carousel from '../common/components/Carousel'
import Button from '../common/components/Button'
import APP_SCREENSHOT_CALENDAR from '../assets/images/app-screenshot-calendar.png'
import APP_SCREENSHOT_ADD from '../assets/images/app-screenshot-add.png'
import APP_SCREENSHOT_DAY from '../assets/images/app-screenshot-day.png'
import APP_SCREENSHOT_REPORT from '../assets/images/app-screenshot-report.png'
import APP_SCREENSHOT_STATS from '../assets/images/app-screenshot-stats.png'

const Home = () => {
  const [screenshotIndex, setScreenshotIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const goToNextScreenshot = () => {
    const nextIndex = screenshotIndex + 1

    setScreenshotIndex(nextIndex <= screenshots.length - 1 ? nextIndex : 0)
  }

  return (
    <>
      <Row>
        <Content>
          <Inner>
            <DualHero
              left={
                () =>
                  <Left>
                    <H1>
                      Know exactly how much you’re spending and on what.
                    </H1>
                    <Badges>
                      <AppStoreBadge />
                      <GooglePlayBadge />
                    </Badges>
                  </Left>
              }
              right={
                () =>
                  <CarouselContainer>
                    <PhoneContainer>
                      <Phone
                        scale={PHONE_SCALE}
                        image={screenshots[screenshotIndex]}
                        onClick={goToNextScreenshot}
                      />
                    </PhoneContainer>
                    <Carousel
                      currentIndex={screenshotIndex}
                      images={screenshots}
                      onChange={index => setScreenshotIndex(index)}
                      style={{
                        marginTop: 20,
                      }}
                    />
                  </CarouselContainer>
              }
            />
          </Inner>
          <AnchorLinkContainer>
            <Button as="a" href="#feature-0">
              Read more
            </Button>
          </AnchorLinkContainer>
        </Content>
      </Row>
      <Features>
        {
          features.map((feature, index) => {
            const isOdd = Boolean(index % 2)
            const RowComponent = isOdd ? PrimaryRow : SecondaryRow

            const imageContent = (
              <FeaturePhoneContainer rotate={isOdd ? '-8deg' : '8deg'}>
                <Phone
                  color={isOdd ? 'gold' : 'silver'}
                  scale={PHONE_SCALE}
                  image={feature.image}
                />
              </FeaturePhoneContainer>
            )

            const textContent = (
              <>
                <H2 as="h2">{feature.heading}</H2>
                {
                  feature.description.split('\n').map(text =>
                    <FeatureDescription key={text}>
                      {text}
                    </FeatureDescription>,
                  )
                }
              </>
            )

            return (
              <ScrollableAnchor id={`feature-${index}`} key={index}>
                <RowComponent>
                  <Content style={{ overflow: 'hidden' }}>
                    <Twins $reverse={isOdd}>
                      <Twin $flex={1} $align={isOdd ? 'flex-start' : 'center'}>
                        {isOdd ? textContent : imageContent}
                      </Twin>
                      <Twin $flex={1} $align={isOdd ? 'center' : 'flex-start'}>
                        {isOdd ? imageContent : textContent}
                      </Twin>
                    </Twins>
                  </Content>
                </RowComponent>
              </ScrollableAnchor>
            )
          })
        }
      </Features>
    </>
  )
}

const PHONE_SCALE = 0.6

const features = [
  {
    heading: 'Calendar overview',
    description: 'The calendar is your home base providing an overview of your monthly expenses.',
    image: APP_SCREENSHOT_CALENDAR,
  },
  {
    heading: 'Add an expense',
    description: 'Save expenses in the currency of your choice. We’ll convert everything back to your default currency.',
    image: APP_SCREENSHOT_ADD,
  },
  {
    heading: 'Stats',
    description: 'A rundown of your expenses, income, and how much you’ve spent on groceries, snacks, etc.',
    image: APP_SCREENSHOT_STATS,
  },
  {
    heading: 'Expense list',
    description: 'A list of expenses on a given day or month. You can also search expenses by name.',
    image: APP_SCREENSHOT_DAY,
  },
  {
    heading: 'Report',
    description: 'See how your spending habits have changed over the course of a given period.',
    image: APP_SCREENSHOT_REPORT,
  },
]

const screenshots = features.map(_ => _.image)

const Inner = styled.div`
  flex: 1;

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    min-height: 100vh;
    margin-top: ${-(APP_BAR_HEIGHT)}px;
  }
`

const H1 = styled(Heading)`
  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    text-align: center;
  }
`

const H2 = styled(Heading)`
  margin-top: 0; margin-bottom: 15px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    text-align: center;
    width: 100%;
  }
`

const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    align-items: center;
    margin-top: 30px;
  }
`

const Badges = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    justify-content: center;
  }
`

const CarouselContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const PhoneContainer = styled.div`
  display: flex;
  height: 526px;
`

const Features = styled.div`
  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    margin-top: 60px;
  }
`

const FeaturePhoneContainer = styled(PhoneContainer)`
  transform: rotate(${props => props.rotate});
  -webkit-backface-visibility: hidden;

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    margin-bottom: -200px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    margin-top: 40px;
    margin-bottom: -260px;
  }
`

const FeatureDescription = styled(P)`
  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    text-align: center;
  }
`

const PrimaryRow = styled(Row)`
`

const SecondaryRow = styled(PrimaryRow)`
  background-color: #f7f7f7;
`

const Twins = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px; margin-bottom: 60px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-direction: ${props => props.$reverse ? 'column' : 'column-reverse'};
  }
`

const Twin = styled.div`
  align-items: ${props => props.$align || 'flex-start'};
  display: flex;
  flex: ${props => props.$flex || 1};
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex: 1;
  }
`

const AnchorLinkContainer = styled.div`
  bottom: 20px; left: 20px; right: 20px;
  display: flex;
  justify-content: center;
  position: absolute;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    display: none;
  }
`

const ScrollableAnchor = styled.div`
`

export default Home
