import { useState } from 'react'
import { createPortal } from 'react-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getLocalStorageValue, setLocalStorageValue } from '../helpers'
import { PRIMARY_COLOR, IVORY, GOLD, VIOLET_LIGHT } from '../styles/colors'
import { MOBILE_MAX_WIDTH } from '../styles/responsive'
import { FontSize } from '../styles/fonts'
import Button from '../components/Button'
import { APP_NAME } from '../constants'

const CookieNotice = ({ style }) => {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(getLocalStorageValue('hasAcceptedCookies'))

  const acceptCookies = () => {
    setHasAcceptedCookies(true)
    setLocalStorageValue('hasAcceptedCookies', true)
  }

  if (hasAcceptedCookies) {
    return null
  }

  return createPortal(
    <Container style={style}>
      <Message>
        By using {APP_NAME}’s services you agree to our <Link to="privacy">cookie policy</Link>.
        We and our partners operate globally and use cookies for analytics,
        personalization, and ads.
      </Message>
      <Button type="button" stretch={true} onClick={acceptCookies}>
        Accept
      </Button>
    </Container>,
    document.getElementById('cookie-notice'),
  )
}

CookieNotice.propTypes = {
  style: PropTypes.object,
}

const Container = styled.div`
  background-color: ${PRIMARY_COLOR};
  bottom: 20px;
  border-radius: 4px;
  color: ${IVORY};
  font-size: ${FontSize.Desktop.SMALL}
  line-height: 1.4;
  left: 20px;
  max-width: 480px;
  padding: 25px;
  position: fixed;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    border-radius: 0;
    bottom: 0;
    font-size: ${FontSize.Mobile.SMALL};
    left: 0; right: 0;
    max-width: 100%;
    padding: 20px;
    width: 100%;
  }
`

const Message = styled.div`
  margin-bottom: 20px;
`

const Link = styled(ReactRouterLink)`
  color: ${GOLD};
  text-decoration: none;

  &:hover {
    color: ${VIOLET_LIGHT};
  }
`

export default CookieNotice
