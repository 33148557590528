import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { Content, Row } from '../components/Grid'
import { ONYX_DARK, GRAY, SILVER } from '../styles/colors'
import PENNYCLERK_GRAY from '../../assets/images/pennyclerk-gray.png'
import { MOBILE_MAX_WIDTH } from '../styles/responsive'
import { APP_NAME } from '../constants'

const Footer = () =>
  <Container>
    <Row>
      <Content style={{ justifyContent: 'center' }}>
        <Split>
          <Left>
            <Link to="/">
              <Logo src={PENNYCLERK_GRAY} height="28" alt={APP_NAME} style={{ marginRight: LINK_PADDING }} />
            </Link>
          </Left>
          <Divider />
          <Right>
            <Ul>
              <Li><TextLink to="privacy">Privacy Policy</TextLink></Li>
              <Li><TextLink to="terms">Terms &amp; Conditions</TextLink></Li>
              <Li><TextLink to="delete-account">Delete Your {APP_NAME} Account</TextLink></Li>
              <Li><NoLink>help@pennyclerk.com</NoLink></Li>
            </Ul>
          </Right>
        </Split>
      </Content>
    </Row>
  </Container>

export const FOOTER_TOP_MARGIN = 160

const Container = styled.div`
  align-items: center; justify-content: center;
  background-color: ${ONYX_DARK};
  color: ${GRAY};
  flex: 1;
  margin-top: ${FOOTER_TOP_MARGIN}px;
  padding-top: 100px; padding-bottom: 100px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    padding-top: 80px; padding-bottom: 80px;
  }
`

const LINK_PADDING = '8px'

const TextLink = styled(Link)`
  border-radius: 5px;
  color: ${SILVER};
  padding: 5px ${LINK_PADDING};
  text-decoration: none;

  &:hover {
    background-color: ${SILVER};
    color: ${ONYX_DARK};
  }
`

const NoLink = styled.span`
  padding: 5px ${LINK_PADDING};
`

const Split = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`

const columnStyle = css`
  flex: 1;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    text-align: center;
  }
`

const Left = styled.div`
  ${columnStyle}
  text-align: right;
`

const Divider = styled.div`
  background-color: ${GRAY};
  margin-left: 70px; margin-right: 70px;
  width: 1px; height: 250px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    display: none;
  }
`

const Right = styled.div`
  ${columnStyle}
  text-align: left;
`

const Logo = styled.img`
  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    height: 24px;
    margin-top: 30px;
  }
`

const Ul = styled.ul`
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  line-height: 2.2;
  list-style: none;
`

export default Footer
