import styled from 'styled-components'
import { ONYX_DARK, PLUM, VIOLET_LIGHT } from '../styles/colors'
import { FontFamily, FontSize } from '../styles/fonts'
import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from '../styles/responsive'

const TextInput = styled.input`
  border: none;
  border-bottom: 1px solid ${VIOLET_LIGHT};
  height: 39px;
  color: ${ONYX_DARK};
  font-family: ${FontFamily.PRIMARY};
  font-weight: 400;
  font-style: normal;
  font-size: ${FontSize.Desktop.MEDIUM};
  line-height: 1.3;
  padding: 3px 10px;
  outline: none;
  transition: border-bottom 0.2s ease-in-out;

  &:focus {
    border-bottom: 1px solid ${PLUM};
  }

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    margin-right: 15px;
    min-width: 260px;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    margin-bottom: 30px;
  }
`

export default TextInput
