import { useEffect } from 'react'

import { Content, Row } from '../common/components/Grid'
import { Heading, Subheading, Ul, Li, P } from '../common/components/Typography'
import { APP_NAME } from '../common/constants'

const TermsOfService = () =>{
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <Row>
      <Content>
        <Heading>Terms of Service</Heading>
        <Subheading>Terms</Subheading>
        <P>By accessing this service, you are agreeing to be bound by these Terms of Service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site or using our mobile app. The materials contained in this web site are protected by applicable copyright and trademark law.</P>
        <P>The materials provided by {APP_NAME} are provided “as is” and without warranties of any kind either express or implied. {APP_NAME} does not warrant or make any representations regarding the use or the results of the use of the content or other materials on this website and app in terms of their correctness, accuracy, reliability, or otherwise.</P>
        <P>Although we have no obligation to monitor User Content, we may delete or modify User Content at any time, for any reason.</P>
        <P>You grant {APP_NAME} consent to revoke User access to the service at any time, for any reason.</P>
        <Subheading>Definitions</Subheading>
        <Ul>
          <Li><em>{APP_NAME}</em> &ndash; This website and the iOS and Android apps, as well as all the services contained within them.</Li>
          <Li><em>User</em> &ndash; An individual person with a {APP_NAME} account and profile.</Li>
          <Li><em>Expense</em> &ndash; An expense record submitted by a User, holding hypothetical data about how much he or she has used on a purchase.</Li>
          <Li><em>Income</em> &ndash; An income record submitted by a User, holding a hypothetical sum of income he or she has accumulated.</Li>
          <Li><em>User Content</em> &ndash; Any content created by a User.</Li>
        </Ul>
      </Content>
    </Row>
  )
}

export default TermsOfService
