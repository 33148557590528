import PropTypes from 'prop-types'

import APP_STORE_BADGE from '../../assets/images/app-store-badge.svg'
import GOOGLE_PLAY_BADGE from '../../assets/images/google-play-badge.png'

export const AppStoreBadge = ({ style }) =>
  <a
    href="https://itunes.apple.com/us/app/pennyclerk/id1271431620?mt=8"
    style={style}
  >
    <img
      src={APP_STORE_BADGE}
      width="105"
      height="35"
      alt="Download on the App Store"
    />
  </a>

AppStoreBadge.propTypes = {
  style: PropTypes.object,
}

export const GooglePlayBadge = ({ style }) =>
  <a
    href="https://play.google.com/store/apps/details?id=com.pennyclerk.app"
    style={style}
  >
    <img
      alt="Get it on Google Play"
      src={GOOGLE_PLAY_BADGE}
      height="52"
      width="135"
      style={style}
    />
  </a>

GooglePlayBadge.propTypes = {
  style: PropTypes.object,
}
