import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Content, Row } from '../components/Grid'
import { FontFamily } from '../styles/fonts'
import { MOBILE_MAX_WIDTH } from '../styles/responsive'
import { APP_NAME } from '../constants'

const BlueScreen = ({ heading = APP_NAME, children, onClick, style }) =>
  <Container
    onClick={onClick ? onClick : () => {}}
    style={style}
  >
    <Row>
      <Content>
        <HeadingContainer>
          <Heading>{heading}</Heading>
        </HeadingContainer>
        {children}
      </Content>
    </Row>
  </Container>

BlueScreen.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

const BLUE = '#0B01AB'
const GRAY = '#A6AAB0'

const FONT_SIZE_DESKTOP = 30
const FONT_SIZE_MOBILE = 24

const onClickStyle = css`
  cursor: pointer;
  outline: none;
  user-select: none;
`

const Container = styled.div`
  background-color: ${BLUE};
  color: ${GRAY};
  display: flex;
  font-family: ${FontFamily.MONOSPACE};
  font-size: ${FONT_SIZE_DESKTOP}px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: ${FONT_SIZE_MOBILE}px;
  }

  ${props => props.onClick && onClickStyle}

  p {
    margin: 0;
    margin-bottom: 1rem;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Heading = styled.h2`
  background-color: ${GRAY};
  color: ${BLUE};
  font-size: ${FONT_SIZE_DESKTOP}px;
  padding: 0 1rem;
  margin: 0;
  margin-bottom: 1rem;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    font-size: ${FONT_SIZE_MOBILE}px;
  }
`

export default BlueScreen
