import { useEffect } from 'react'

import { Content, Row } from '../common/components/Grid'
import { Heading, Subheading, Ul, Li, P } from '../common/components/Typography'
import { APP_NAME } from '../common/constants'

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Row>
      <Content>
        <Heading>Privacy Policy</Heading>
        <Subheading>What information do we collect?</Subheading>
        <P>We collect information from you when you sign up for an account or submit a form.</P>
        <P>When ordering or registering in our mobile app, as appropriate, you may be asked to enter your: name, e-mail address or credit card information. You may, however, visit our site anonymously.</P>
        <P>Our mobile app and website anonymously track user interactions to optimize the user experience and to catch software errors.</P>
        <Subheading>What do we use your information for?</Subheading>
        <P>Any of the information we collect from you may be used in one of the following ways:</P>
        <Ul>
          <Li>To personalize your experience (your information helps us to better respond to your individual needs)</Li>
          <Li>To improve our service (we continually strive to improve our offerings based on the information and feedback we receive from you)</Li>
          <Li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)</Li>
          <Li>To process transactions (your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested)</Li>
          <Li>To send periodic emails (the email address you provide for order processing, may be used to send you information and updates pertaining to your order, in addition to receiving occasional company news, updates, related product or service information, etc.)</Li>
          <Li>To calculate and publish anonymous average statistics of people’s spending habits.</Li>
        </Ul>
        <Subheading>How do we protect your information?</Subheading>
        <P>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.</P>
        <Subheading>Cookies</Subheading>
        <P>This website only uses anonymous cookies to collect usage statistics for Google Analytics and for sharing and liking the page on Facebook.</P>
        <P>The mobile app uses cookie-like technologies, such as AsyncStorage, to provide a better and safer experience. This includes logging you into {APP_NAME}, saving your preferences, and presenting personalized content.</P>
        <Subheading>Security</Subheading>
        <P>Your authentication information, including your password, is encrypted and stored in our system. We use the bcrypt algorithm to encrypt password hashes. Passwords are never stored as plain text. The JSON Web Token standard is used to create encrypted user identities on devices running our services, and they automatically expire after 24 hours and are renewed on subsequent usage.</P>
        <Subheading>Do we disclose any information to outside parties?</Subheading>
        <P>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website and mobile app, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</P>
        <Subheading>Third party links</Subheading>
        <P>Occasionally, at our discretion, we may include or offer third party products or services on our website and mobile app. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</P>
        <Subheading>Sharing your information</Subheading>
        <P>By default, all the content (such as expenses and income) you submit is visible to you only. You may, however, choose to employ some of the sharing features provided on our website and mobile app to make parts of your content public.</P>
        <Subheading>Your consent</Subheading>
        <P>By using our site or mobile app, you consent to our privacy policy.</P>
        <Subheading>Right to be forgotten</Subheading>
        <P>If you wish to have your information permanently removed from our system, please email us at help@pennyclerk.com.</P>
      </Content>
    </Row>
  )
}

export default PrivacyPolicy
