import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import AppBar from '../common/components/AppBar'
import Footer from '../common/components/Footer'
import CookieNotice from '../common/components/CookieNotice'
import Home from '../pages/Home'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import TermsOfService from '../pages/TermsOfService'
import DeleteAccount from '../pages/DeleteAccount'
import Stats from '../pages/Stats'
import NotFound from '../pages/NotFound'
import GlobalStyle from '../common/styles/GlobalStyle'

const AppRoutes = () =>
  <Router>
    <GoogleReCaptchaProvider reCaptchaKey="6LdG6m4pAAAAAFVL1y4_q-D1Ze7cOCF7D-DyvGfP">
      <GlobalStyle />
      <Page>
        <AppBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/terms" element={<TermsOfService />} />
          <Route exact path="/delete-account" element={<DeleteAccount />} />
          <Route exact path="/stats" element={<Stats />} />
          <Route element={<NotFound />} />
        </Routes>
        <Footer />
        <CookieNotice />
      </Page>
    </GoogleReCaptchaProvider>
  </Router>

const Page = styled.div`
  flex: 1;
  flex-direction: column;
`

export default AppRoutes
