import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from '../styles/responsive'

const DualHero = ({ left, right, containerStyle, leftStyle, rightStyle }) =>
  <Container style={containerStyle}>
    <Left style={leftStyle}>
      {left()}
    </Left>
    <Right style={rightStyle}>
      {right()}
    </Right>
  </Container>

DualHero.propTypes = {
  left: PropTypes.func.isRequired,
  right: PropTypes.func.isRequired,
  containerStyle: PropTypes.object,
  leftStyle: PropTypes.object,
  rightStyle: PropTypes.object,
}

const Container = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-direction: column-reverse;
    margin-top: 0;
    width: 100%;
  }
`

const columnStyle = css`
  align-items: center;
  display: flex;
`

const Left = styled.div`
  ${columnStyle}

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    flex: 3;
  }
`

const Right = styled.div`
  ${columnStyle}
  justify-content: flex-end;

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    flex: 2;
  }

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    justify-content: center;
  }
`

export default DualHero
