import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Content, Row } from '../common/components/Grid'
import { FontFamily } from '../common/styles/fonts'
import { PLUM, IVORY } from '../common/styles/colors'
import { MOBILE_MAX_WIDTH } from '../common/styles/responsive'
import { getApiBaseUrl } from '../common/helpers'

const Stats = () => {
  const [stats, setStats] = useState()

  useEffect(() => {
    window.scrollTo(0, 0)

    fetch(`${getApiBaseUrl()}/v1/stats/users`)
      .then(response => response.json())
      .then(stats => setStats(stats))
  }, [])

  if (!stats) {
    return null
  }

  return (
    <Row>
      <Content>
        <Body>
          <StatRow>
            <BigNumber>{stats.users.confirmed}</BigNumber>
            <Label>total users</Label>
          </StatRow>
          <StatRow>
            <BigNumber>{stats.users.active}</BigNumber>
            <Label>active users (past 14 days)</Label>
          </StatRow>
          {
            Object.keys(stats.users.byAppVersionNumber)
              .sort((a, b) => b - a)
              .map(versionNumber => {
                const numberOfUsers = stats.users.byAppVersionNumber[versionNumber]
                const personOrPeople = numberOfUsers === 1 ? 'person' : 'people'

                return (
                  <StatRow key={versionNumber}>
                    <BigNumber>{numberOfUsers}</BigNumber>
                    <Label>{personOrPeople} using app version {versionNumber}</Label>
                  </StatRow>
                )
              })
          }
        </Body>
      </Content>
    </Row>
  )
}

const Body = styled.div`
  margin-top: 40px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    margin-top: 0;
  }
`

const StatRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-direction: column;
    margin-top: 3rem; margin-bottom: 0;
  }
`

const BigNumber = styled.div`
  background-color: ${PLUM};
  border-radius: 4px;
  color: ${IVORY};
  display: flex;
  font-family: ${FontFamily.MONOSPACE};
  font-size: 60px;
  font-weight: 600;
  justify-content: center;
  margin-right: 1rem;
  padding: 0 1rem;
  min-width: 160px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

const Label = styled.div`
  color: ${PLUM};
  font-family: ${FontFamily.MONOSPACE};
  font-size: 26px;
  text-transform: uppercase;
`

export default Stats
