import { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Content, Row } from '../common/components/Grid'
import { Heading, P } from '../common/components/Typography'
import { APP_NAME } from '../common/constants'
import Button from '../common/components/Button'
import { DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from '../common/styles/responsive'
import { getApiBaseUrl } from '../common/helpers'
import TextInput from '../common/components/TextInput'
import { ONYX, ORANGE_LIGHT } from '../common/styles/colors'

const STEP_INITIAL = 'STEP_INITIAL'
const STEP_CODE_SENT = 'STEP_CODE_SENT'
const STEP_DELETE_SUCCESS = 'STEP_DELETE_SUCCESS'

const DeleteAccount = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getReCaptchaToken = useCallback(async () => {
    const token = await executeRecaptcha('yourAction')

    return token
  }, [executeRecaptcha])

  const [form, setForm] = useState({
    email: '',
    code: '',
    error: '',
    step: STEP_INITIAL,
  })

  const onSendCode = async () => {
    try {
      if (!form.email) {
        return
      }

      const reCaptchaToken = await getReCaptchaToken()

      const response = await fetch(`${getApiBaseUrl()}/v1/auth/request-user-deactivation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: form.email,
          reCaptchaToken,
        }),
      })

      if (response.ok) {
        setForm({ ...form, error: '', step: STEP_CODE_SENT })
      } else {
        if (response.status === 429) {
          setForm({ ...form, error: 'Too many requests. Please try again later.' })
        } else {
          throw new Error()
        }
      }
    } catch (e) {
      setForm({ ...form, error: 'An error occurred. Please try again later.' })
    }
  }

  const onConfirmDelete = async () => {
    try {
      if (!form.email || !form.code) {
        return
      }

      const reCaptchaToken = await getReCaptchaToken()

      const response = await fetch(`${getApiBaseUrl()}/v1/auth/confirm-user-deactivation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: form.email,
          code: form.code,
          reCaptchaToken,
        }),
      })

      if (response.ok) {
        setForm({ ...form, error: '', step: STEP_DELETE_SUCCESS })
      } else {
        if (response.status === 429) {
          setForm({ ...form, error: 'Too many requests. Please try again later.' })
        } else {
          throw new Error()
        }
      }
    } catch (e) {
      setForm({ ...form, error: 'An error occurred. Please try again later.' })
    }
  }

  return (
    <Row>
      <Content>
        <Heading>Delete Your {APP_NAME} Account</Heading>
        {
          form.step === STEP_INITIAL &&
          <>
            <P>
              If you want to have your {APP_NAME} account deleted, please enter your email address below.
            </P>
            <P>
              We will send you an email with a code to confirm your deletion request.
            </P>
            <form onSubmit={(event) => {
              event.preventDefault()
              onSendCode()
            }}>
              <FormRow>
                <TextInput
                  type="email"
                  placeholder="Your email address"
                  value={form.email}
                  onChange={({ target }) => setForm((prevForm) => ({ ...prevForm, email: target.value }))}
                />
              </FormRow>
              <FormRow>
                <Button type="submit">
                  Send Deletion Code
                </Button>
              </FormRow>
            </form>
          </>
        }
        {
          form.step === STEP_CODE_SENT &&
          <>
            <P>
              We have sent you an email with your confirmation code.
            </P>
            <P>
              Enter the code below to permanently delete your {APP_NAME} account.
            </P>
            <form onSubmit={(event) => {
              event.preventDefault()
              onConfirmDelete()
            }}>
              <FormRow>
                <TextInput
                  type="email"
                  placeholder="Your email address"
                  value={form.email}
                  onChange={({ target }) => setForm((prevForm) => ({ ...prevForm, email: target.value }))}
                />
                <TextInput
                  type="number"
                  placeholder="Deletion code"
                  value={form.code}
                  onChange={({ target }) => setForm((prevForm) => ({ ...prevForm, code: target.value }))}
                />
              </FormRow>
              <FormRow>
                <Button type="submit">
                  Permanently Delete Your Account
                </Button>
              </FormRow>
            </form>
          </>
        }
        {
          form.step === STEP_DELETE_SUCCESS &&
          <>
            <P>Your {APP_NAME} account has now been permanently deleted.</P>
            <Link to="/">Back to home</Link>
          </>
        }
        {
          Boolean(form.error) &&
          <ErrorMessage>{form.error}</ErrorMessage>
        }
      </Content>
    </Row>
  )
}

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

  @media (max-width: ${MOBILE_MAX_WIDTH}) {
    flex-direction: column;
  }
`

const ErrorMessage = styled.div`
  background-color: ${ORANGE_LIGHT};
  border-radius: 4px;
  color: ${ONYX};
  margin-top: 40px;
  padding: 30px;
  display: inline-block;

  @media (min-width: ${DESKTOP_MIN_WIDTH}) {
    max-width: 650px;
  }
`

export default DeleteAccount
